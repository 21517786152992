<template>
	<div class="stock">
		<div class="tool">
			<el-button type="primary" @click="remind" size="mini">定价提醒</el-button>
			<el-button type="primary" @click="recommend" size="mini">智能选股</el-button>
		</div>
		<div class="stockSearch">
			<el-input v-model="stockNum" @change="quotation" placeholder="请输入股票号码" size="medium"></el-input>
			<el-button type="primary" @click="quotation" size="medium">搜索</el-button>
		</div>
		<div class="name">
			<span>{{quotationData[1]}}</span>
			<span ref="xianjia">{{quotationData[3]}}</span>
		</div>
		<div class="time">
			<span>更新时间:</span>
			<span>{{getTime}}</span>
		</div>
		<div class="stockDatas">
			<div>
				<span>涨幅:</span>
				<span ref="zhangfu">{{quotationData[32]}}%</span>
				<span>涨跌:</span>
				<span ref="zhangdie">{{quotationData[31]}}</span>
			</div>
			<div>
				<span>昨收:</span>
				<span>{{quotationData[4]}}</span>
				<span>今开:</span>
				<span ref="jinkai">{{quotationData[5]}}</span>
			</div>
			<div>
				<span>最高:</span>
				<span ref="zuigao">{{quotationData[33]}}</span>
				<span>最低:</span>
				<span ref="zuidi">{{quotationData[34]}}</span>
			</div>
			<div>
				<span>振幅:</span>
				<span>{{quotationData[43]}}%</span>
			</div>
			<div>
				<span title="涨停价">涨停:</span>
				<span style="color: red;">{{quotationData[47]}}</span>
				<span title="跌停价">跌停:</span>
				<span style="color: green;">{{quotationData[48]}}</span>
			</div>
			<div>
				<span>换手率:</span>
				<span>{{quotationData[38]}}%</span>
				<span>成交量:</span>
				<span>{{quotationData[6]}}手</span>
			</div>
			<div>
				<span>成交额:</span>
				<span>{{quotationData[37]}}万</span>
			</div>
			<div>
				<span>外盘:</span>
				<span>{{quotationData[7]}}手</span>
				<span>内盘:</span>
				<span>{{quotationData[8]}}手</span>
			</div>
			<div>
				<span title="总市值">总值:</span>
				<span>{{quotationData[45]}}亿</span>
				<span title="流通市值">流值:</span>
				<span>{{quotationData[44]}}亿</span>
			</div>
			<div>
				<span>市盈率:</span>
				<span>{{quotationData[39]}}</span>
				<span>市净率:</span>
				<span>{{quotationData[46]}}</span>
			</div>
		</div>
		<!-- <div>
			<span>最近逐笔成交:</span>
			<span>{{quotationData[29]}}</span>
		</div>
		<div>
			<span>价格/成交量(手)/成交额:</span>
			<span>{{quotationData[35]}}</span>
		</div> -->
		<div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="分时" name="minute">
					<div class="minute">
						<div class="drawChart1" ref="drawChart1"></div>
						<div class="market">
							<div>
								<span>档位</span>
								<span>价格</span>
								<span title="成交量">量(手)</span>
							</div>
							<div>
								<span>卖5:</span>
								<span>{{quotationData[27]}}</span>
								<span>{{quotationData[28]}}</span>
							</div>
							<div>
								<span>卖4:</span>
								<span>{{quotationData[25]}}</span>
								<span>{{quotationData[26]}}</span>
							</div>
							<div>
								<span>卖3:</span>
								<span>{{quotationData[23]}}</span>
								<span>{{quotationData[24]}}</span>
							</div>
							<div>
								<span>卖2:</span>
								<span>{{quotationData[21]}}</span>
								<span>{{quotationData[22]}}</span>
							</div>
							<div>
								<span>卖1:</span>
								<span>{{quotationData[19]}}</span>
								<span>{{quotationData[20]}}</span>
							</div>
							<div>
								<span>买1:</span>
								<span>{{quotationData[9]}}</span>
								<span>{{quotationData[10]}}</span>
							</div>
							<div>
								<span>买2:</span>
								<span>{{quotationData[11]}}</span>
								<span>{{quotationData[12]}}</span>
							</div>
							<div>
								<span>买3:</span>
								<span>{{quotationData[13]}}</span>
								<span>{{quotationData[14]}}</span>
							</div>
							<div>
								<span>买4:</span>
								<span>{{quotationData[15]}}</span>
								<span>{{quotationData[16]}}</span>
							</div>
							<div>
								<span>买5:</span>
								<span>{{quotationData[17]}}</span>
								<span>{{quotationData[18]}}</span>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="五日" name="fiveDay">
					<div class="drawChart2" ref="drawChart2" style="width: 100%;"></div>
				</el-tab-pane>
				<el-tab-pane label="日K" name="daily">
					<div ref="drawChart3" style="width: 100%; height: 600px;"></div>
				</el-tab-pane>
				<el-tab-pane label="周K" name="weekly">
					<div ref="drawChart4" style="width: 100%; height: 600px;"></div>
				</el-tab-pane>
				<el-tab-pane label="月K" name="monthly">
					<div ref="drawChart5" style="width: 100%; height: 600px;"></div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '成本计算器',
			meta: [
				{
					charset: "utf-8"
				},
				{
					name: 'description,',
					content: '这是一个成本计算器网页,可以帮助用户计算股票或其他交易的买入成本、剩余成本、初始成本、初始股数、手股、初始金额、买入价格、买入数量、所需金额、手续费率、最终股本、最终股数、最终成本以及保本卖出价。用户可以根据自己的交易情况,输入相关参数,快速了解自己的交易成本'
				},
				{
					name: 'keywords',
					content: '成本计算器,股票成本计算,交易成本计算,买入成本,卖出成本,手续费率,股票收益,保本价格,投资分析'
				}],
			link: [
				{
					rel: 'canonical',
					href: 'https://www.itptg.com/stockcost/'
				}
			]
		},
		data() {
			return {
				stockNum: "",
				quotationData: [],
				fundFlowData: [],
				pkAnalyseData: [],
				activeName: 'minute',
				echart: null,
				secondInterval:"",
				minuteInterval:"",
				refresh:false,
			}
		},
		computed: {
			getTime() {
				if(this.quotationData[30]!=undefined){
					let time = this.quotationData[30];
					let year = time.substr(0, 4);
					let month = time.substr(4, 2);
					let day = time.substr(6, 2);
					let hour = time.substr(8, 2);
					let minute = time.substr(10, 2);
					let second = time.substr(12, 2);
					return year + "年" + month + "月" + day + "日 " + hour + ":" + minute + ":" + second;
				}else{
					return "";
				}
			}
		},
		methods: {
			getStockNumber(type){
				let stockNum = this.$store.getters.getStockNum;
				if (stockNum.substr(0, 1) == "0" || stockNum.substr(0, 1) == "2" || stockNum.substr(0, 1) == "3") {
					if(type == "quotation"){
						return "sz" + stockNum;
					}else if(type == "fundFlow"){
						return "ff_sz" + stockNum;
					}else if(type == "pkAnalyse"){
						return "s_pksz" + stockNum;
					}else if(type == "dataArray"){
						return "sz" + stockNum + ".js";
					}else if(type == "minute2"){
						return "1" + stockNum + ".json";
					}
				} else if (stockNum.substr(0, 1) == "6" || stockNum.substr(0, 1) == "9") {
					if(type == "quotation"){
						return "sh" + stockNum;
					}else if(type == "fundFlow"){
						return "ff_sh" + stockNum;
					}else if(type == "pkAnalyse"){
						return "s_pksh" + stockNum;
					}else if(type == "dataArray"){
						return "sh" + stockNum + ".js";
					}else if(type == "minute2"){
						return "0" + stockNum + ".json";
					}
				}else{
					this.$message.error('请输入正确的股票号码');
					return "";
				}
			},
			//最新行情:http://qt.gtimg.cn/q=sh601003
			quotation() {
				this.$store.commit("setStockNum", this.stockNum);
				let num = this.getStockNumber("quotation");
				if(num=="")return;

				let _this = this;
				_this.$axios.get("/qt/q=" + num).then(
					function(response) {
						if(response.data.indexOf('none')!=-1){
							_this.$message.error('输入的股票号码有误');
							return;
						}
						_this.quotationData = response.data.split('\"');
						_this.quotationData = _this.quotationData[1].split("~");
						// console.log(_this.quotationData);
						_this.init();
						_this.handleClick();
					},
					function(err) {}
				)
			},
			//资金流向:http://qt.gtimg.cn/q=ff_sh600519
			fundFlow() {
				let num = this.getStockNumber("fundFlow");
				if(num=="")return;

				var _this = this;
				_this.$axios.get("/qt/q=" + num).then(
					function(response) {
						_this.fundFlowData = response.data.split('\"');
						_this.fundFlowData = _this.fundFlowData[1].split("~");
						console.log(_this.fundFlowData);
					},
					function(err) {}
				)
			},
			//盘口分析:http://qt.gtimg.cn/q=s_pksh600519
			pkAnalyse() {
				let num = this.getStockNumber("pkAnalyse");
				if(num=="")return;

				var _this = this;
				_this.$axios.get("/qt/q=" + num).then(
					function(response) {
						_this.pkAnalyseData = response.data.split('\"');
						_this.pkAnalyseData = _this.pkAnalyseData[1].split('~');
						console.log(_this.pkAnalyseData);
					},
					function(err) {}
				)
			},


			//分时数据:https://data.gtimg.cn/flashdata/hushen/minute/sh600519.js
			minute() {
				let num = this.getStockNumber("dataArray");
				if(num=="")return;

				var _this = this;
				_this.$axios.get("/gt/flashdata/hushen/minute/" + num).then(
					function(response) {
						let datas = response.data.split('\"');
						datas = datas[1].split('\\n\\\n');
						datas.shift() //删除数组第一个空元素
						datas.pop() //删除数组最后一个空元素
						let xAxis = new Array();
						let priceDatas = new Array();
						let rangeDatas = new Array();
						for(var i=1; i<datas.length; i++){
							let arr = datas[i].split(" ");
							xAxis.push(arr[0].substr(0,2) + ':' + arr[0].substr(2,2));
							priceDatas.push(arr[1]);
							rangeDatas.push(arr[key][1]/_this.quotationData[4]);
						}
						let chartDom = _this.$refs.drawChart1;
						let option = _this.minuteChartOption(xAxis,priceDatas,rangeDatas);
						_this.drawChart(chartDom, option);
						// console.log(xAxis);
						// console.log(priceDatas);
						// console.log(rangeDatas);
					},
					function(err) {}
				)
			},
			//分时数据:http://img1.money.126.net/data/hs/time/today/0600519.json
			minute2() {
				let num = this.getStockNumber("minute2");
				if(num=="")return;

				var _this = this;
				_this.$axios.get("/money/data/hs/time/today/" + num).then(
					function(response) {
						let arr = new Array();
						let xAxis = ["09:30","09:31","09:32","09:33","09:34","09:35","09:36","09:37","09:38","09:39","09:40",
						"09:41","09:42","09:43","09:44","09:45","09:46","09:47","09:48","09:49","09:50","09:51","09:52","09:53","09:54","09:55",
						"09:56","09:57","09:58","09:59","10:00","10:01","10:02","10:03","10:04","10:05","10:06","10:07","10:08","10:09","10:10",
						"10:11","10:12","10:13","10:14","10:15","10:16","10:17","10:18","10:19","10:20","10:21","10:22","10:23","10:24","10:25",
						"10:26","10:27","10:28","10:29","10:30","10:31","10:32","10:33","10:34","10:35","10:36","10:37","10:38","10:39","10:40",
						"10:41","10:42","10:43","10:44","10:45","10:46","10:47","10:48","10:49","10:50","10:51","10:52","10:53","10:54","10:55",
						"10:56","10:57","10:58","10:59","11:00","11:01","11:02","11:03","11:04","11:05","11:06","11:07","11:08","11:09","11:10",
						"11:11","11:12","11:13","11:14","11:15","11:16","11:17","11:18","11:19","11:20","11:21","11:22","11:23","11:24","11:25",
						"11:26","11:27","11:28","11:29","11:30","13:00","13:01","13:02","13:03","13:04","13:05","13:06","13:07","13:08","13:09",
						"13:10","13:11","13:12","13:13","13:14","13:15","13:16","13:17","13:18","13:19","13:20","13:21","13:22","13:23","13:24",
						"13:25","13:26","13:27","13:28","13:29","13:30","13:31","13:32","13:33","13:34","13:35","13:36","13:37","13:38","13:39",
						"13:40","13:41","13:42","13:43","13:44","13:45","13:46","13:47","13:48","13:49","13:50","13:51","13:52","13:53","13:54",
						"13:55","13:56","13:57","13:58","13:59","14:00","14:01","14:02","14:03","14:04","14:05","14:06","14:07","14:08","14:09",
						"14:10","14:11","14:12","14:13","14:14","14:15","14:16","14:17","14:18","14:19","14:20","14:21","14:22","14:23","14:24",
						"14:25","14:26","14:27","14:28","14:29","14:30","14:31","14:32","14:33","14:34","14:35","14:36","14:37","14:38","14:39",
						"14:40","14:41","14:42","14:43","14:44","14:45","14:46","14:47","14:48","14:49","14:50","14:51","14:52","14:53","14:54",
						"14:55","14:56","14:57","14:58","14:59","15:00"];
						let priceDatas = new Array();
						let rangeDatas = new Array();
						arr = response.data.data;
						for(let key in arr){
							// xAxis.push(arr[key][0].substr(0,2) + ':' + arr[key][0].substr(2,2));
							priceDatas.push(arr[key][1]);
							rangeDatas.push(((arr[key][1]/_this.quotationData[4]-1)*100).toFixed(2));
						}
						let chartDom = _this.$refs.drawChart1;
						let option = _this.minuteChartOption(xAxis,priceDatas,rangeDatas);
						_this.drawChart(chartDom, option);
						// console.log(xAxis);
						// console.log(priceDatas);
						// console.log(rangeDatas);
					},
					function(err) {}
				)
			},
			//五天分时数据:https://data.gtimg.cn/flashdata/hushen/4day/sh/sh600519.js
			fiveDayMinute() {
				let num = this.getStockNumber("dataArray");
				if(num=="")return;

				var _this = this;
				_this.$axios.get("/gt/flashdata/hushen/4day/sh/" + num).then(
					function(response) {
						let arr = response.data.split('=');
						arr = arr[1].replace(";", "");
						while (arr.indexOf("'") !== -1) {
							arr = arr.replace("'", "\"");
						}
						// console.log(JSON.parse(arr));
						let json = JSON.parse(arr);
						let xAxis = new Array();
						let dates = new Array();
						let datas = new Array();
						for(let i=json.length-1; i>=0; i--){
							dates.push(json[i].date.substr(0,2) + "/" + json[i].date.substr(2,2) + "/" + json[i].date.substr(4,2));
							let dayArr = json[i].data.split("^");
							dayArr.pop() //删除数组最后一个空元素
							for(let j=0; j<dayArr.length; j++){
								let dataArr = dayArr[j].split("~");
								xAxis.push(dataArr[0].substr(0,2) + ':' + dataArr[0].substr(2,2));
								datas.push(dataArr[1]);
							}
						}
						let chartDom = _this.$refs.drawChart2;
						let option = _this.fiveDayMinuteChartOption(xAxis,dates,datas);
						_this.drawChart(chartDom, option);
						// console.log(xAxis);
						// console.log(dates);
						// console.log(datas);
					},
					function(err) {}
				)
			},


			getdayDatas(datas){
				datas = datas.split('\"');
				datas = datas[1].split('\\n\\\n');
				datas.shift() //删除数组第一个空元素
				datas.pop() //删除数组最后一个空元素
				for(var key in datas){
					let array = datas[key].split(' ')
					array[0] = '20' + array[0].substr(0,2) + '-' + array[0].substr(2,2) + '-' + array[0].substr(4,2);
					array[1] = parseFloat(array[1]);
					array[2] = parseFloat(array[2]);
					array[3] = parseFloat(array[3]);
					array[4] = parseFloat(array[4]);
					array[5] = parseInt(array[5]);
					datas[key] = array;
				}
				// console.log(datas);
				return datas;
			},
			//日K线数据：https://data.gtimg.cn/flashdata/hushen/daily/13/sh600519.js
			daily() {
				let num = this.getStockNumber("dataArray");
				if(num=="")return;

				var _this = this;
				_this.$axios.get("/gt/flashdata/hushen/daily/22/" + num).then(
					function(response) {
						let datas = _this.getdayDatas(response.data);
						let chartDom = _this.$refs.drawChart3;
						let option = _this.candlestickOption(datas, 60);
						let dispatch = _this.dispatchAction('2022-07-22', '2022-08-01')
						_this.drawChart(chartDom, option, dispatch);
					},
					function(err) {}
				)
			},
			//周K线数据：https://data.gtimg.cn/flashdata/hushen/weekly/sh600519.js
			weekly() {
				let num = this.getStockNumber("dataArray");
				if(num=="")return;

				var _this = this;
				_this.$axios.get("/gt/flashdata/hushen/weekly/" + num).then(
					function(response) {
						let datas = _this.getdayDatas(response.data);
						let chartDom = _this.$refs.drawChart4;
						let option = _this.candlestickOption(datas, 95);
						let dispatch = _this.dispatchAction('2022-04-22', '2022-05-27')
						_this.drawChart(chartDom, option, dispatch);
					},
					function(err) {}
				)
			},
			//获取月K线数据：https://data.gtimg.cn/flashdata/hushen/monthly/sh600519.js
			monthly() {
				let num = this.getStockNumber("dataArray");
				if(num=="")return;

				let _this = this;
				_this.$axios.get("/gt/flashdata/hushen/monthly/" + num).then(
					function(response) {
						let datas = _this.getdayDatas(response.data);
						let chartDom = _this.$refs.drawChart5;
						let option = _this.candlestickOption(datas, 80);
						let dispatch = _this.dispatchAction('2021-06-30', '2021-11-30')
						_this.drawChart(chartDom, option, dispatch);
					},
					function(err) {}
				)
			},


			minuteChartOption(xAxisDatas, priceDatas, rangeDatas){
				let option = {
					grid: {
						left: '10%',
						right: '10%',
						bottom: '10%',
					},
					tooltip : {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							animation: false,
							label: {
								backgroundColor: '#505765',
							},
						},
						formatter:function(params) {
							let str = '<div style="position:relative"><div style="width:120px;"></div>' + params[0].axisValue + '<br>';
							str += params[0].marker;
							str += params[0].seriesName + '<span style="position:absolute;right:0;">' + params[0].value + '</span><br>';
							str += params[1].marker;
							str += params[1].seriesName + '<span style="position:absolute;right:0;">' + params[1].value + '%</span><br></div>';
							return str;
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						axisLine: {onZero: false},
						data: xAxisDatas,
					},
					yAxis: [
						{
							name: '价格',
							type: 'value',
							scale: true,
							splitNumber: 4,
						},
						{
							name: '涨跌幅(%)',
							type: 'value',
							scale: true,
							splitNumber: 4,
							splitLine: false,
						},
					],
					series: [{
						name: '价格',
						type: 'line',
						animation: false,
						symbol: 'none',
						lineStyle: {
							width: 1,
						},
						data: priceDatas,
						zlevel: 1,
						z: 1,
					},
					{
						name: '涨跌幅',
						type: 'line',
						yAxisIndex: 1,
						animation: false,
						symbol: 'none',
						data: rangeDatas,
						lineStyle: {
							width: 1,
							color: 'transparent',
						},
						markLine: {
							silent: true,
							// 去掉箭头
							symbol: 'none',
							data: [{
								yAxis: 0,
							}],
							label: {
								formatter: '',
							},
						},
						zlevel: 2,
						z: 2,
					}]
				};

				return option;
			},

			fiveDayMinuteChartOption(xAxisDatas, xAxisDisDates, priceDatas){
				let option = {
					grid: {
						left: '10%',
						right: '10%',
						bottom: '10%',
					},
					tooltip : {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							animation: false,
							label: {
								backgroundColor: '#505765',
							},
						},
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						axisLine: {onZero: false},
						data: xAxisDatas,
						axisTick: {
							show: false,    // 是否显示坐标轴刻度
						},
						axisLabel: {
							interval: '0',
							formatter: function (value, idx) {
								let x1 = idx % 121;
								let x2 = idx % 242;
								if (x1 == 0 && x2 != 0) {
									return xAxisDisDates[Math.floor(idx/242)];
								}
								else{
									return "";
								}
							}
						}
					},
					yAxis: [
						{
							name: '价格',
							type: 'value',
							scale: true,
							splitNumber: 4,
						},
						{
							name: '涨跌幅(%)',
							type: 'value',
							scale: true,
							splitNumber: 4,
							splitLine: false,
						},
					],
					series: [{
						name: '价格',
						type: 'line',
						animation: false,
						symbol: 'none',
						lineStyle: {
							width: 1,
						},
						data: priceDatas,
						zlevel: 1,
						z: 1,
					},
					{
						name: '涨跌幅',
						type: 'line',
						yAxisIndex: 1,
						animation: false,
						symbol: 'none',
						lineStyle: {
							width: 1,
							color: 'transparent',
						},
						markLine: {
							silent: true,
							// 去掉箭头
							symbol: 'none',
							data: [{
								yAxis: 0,
							}],
							label: {
								formatter: '',
							},
						},
						data: priceDatas,
						zlevel: 2,
						z: 2,
					}]
				};

				return option;
			},

			splitData(rawData) {
				let categoryData = [];
				let values = [];
				let volumes = [];
				for (let i = 0; i < rawData.length; i++) {
					categoryData.push(rawData[i].splice(0, 1)[0]);
					values.push(rawData[i]);
					volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
				}
				return {
					categoryData: categoryData,
					values: values,
					volumes: volumes
				};
			},
			calculateMA(dayCount, data) {
				var result = [];
				for (var i = 0, len = data.values.length; i < len; i++) {
					if (i < dayCount) {
						result.push('-');
						continue;
					}
					var sum = 0;
					for (var j = 0; j < dayCount; j++) {
						sum += data.values[i - j][1];
			  }
					result.push(+(sum / dayCount).toFixed(3));
				}
				return result;
			},
			dispatchAction(coordRangeStart, coordRangeEnd){
				let dispatch = {
					type: 'brush',
					areas: [{
						brushType: 'lineX',
						coordRange: [coordRangeStart, coordRangeEnd],
						xAxisIndex: 0
					}]
				}
				return dispatch;
			},
			candlestickOption(datas, dataZoomStart){
				const upColor = '#00da3c';
				const downColor = '#ec0000';
				let chartDatas = this.splitData(datas);

				let option = {
						animation: false,
						legend: {
							bottom: 10,
							left: 'center',
							data: ['Dow-Jones index', 'MA5', 'MA10', 'MA20', 'MA30']
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'cross'
							},
							borderWidth: 1,
							borderColor: '#ccc',
							padding: 10,
							textStyle: {
							color: '#000'
						},
						position: function(pos, params, el, elRect, size) {
							const obj = {
								top: 10
							};
							obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
							return obj;
						}
						// extraCssText: 'width: 170px'
					},
					axisPointer: {
						link: [{
							xAxisIndex: 'all'
						}],
						label: {
							backgroundColor: '#777'
						}
					},
					toolbox: {
						feature: {
							dataZoom: {
								yAxisIndex: false
							},
							brush: {
								type: ['lineX', 'clear']
							}
						}
					},
					brush: {
						xAxisIndex: 'all',
						brushLink: 'all',
						outOfBrush: {
							colorAlpha: 0.1
						}
					},
					visualMap: {
						show: false,
						seriesIndex: 5,
						dimension: 2,
						pieces: [{
							value: 1,
							color: downColor
						},
						{
							value: -1,
							color: upColor
						}]
					},
					grid: [{
						left: '10%',
						right: '8%',
						height: '50%'
					},
					{
						left: '10%',
						right: '8%',
						top: '63%',
						height: '16%'
					}],
					xAxis: [{
						type: 'category',
						data: chartDatas.categoryData,
						boundaryGap: false,
						axisLine: {
							onZero: false
						},
						splitLine: {
							show: false
						},
						min: 'dataMin',
						max: 'dataMax',
						axisPointer: {
							z: 100
						}
					},
					{
						type: 'category',
						gridIndex: 1,
						data: chartDatas.categoryData,
						boundaryGap: false,
						axisLine: {
							onZero: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						min: 'dataMin',
						max: 'dataMax'
					}],
					yAxis: [{
						scale: true,
						splitArea: {
							show: true,
						}
					},
					{
						scale: true,
						gridIndex: 1,
						splitNumber: 2,
						axisLabel: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						}
					}],
					dataZoom: [{
						type: 'inside',
						xAxisIndex: [0, 1],
						start: dataZoomStart,
						end: 100
					},
					{
						show: true,
						xAxisIndex: [0, 1],
						type: 'slider',
						top: '85%',
						start: 98,
						end: 100
					}],
					series: [{
						name: 'Dow-Jones index',
						type: 'candlestick',
						data: chartDatas.values,
						itemStyle: {
							color: upColor,
							color0: downColor,
							borderColor: undefined,
							borderColor0: undefined
						},
						tooltip: {
							formatter: function(param) {
								param = param[0];
								return [
										'Date: ' + param.name +
										'<hr size=1 style="margin: 3px 0">',
										'Open: ' + param.data[0] + '<br/>',
										'Close: ' + param.data[1] + '<br/>',
										'Lowest: ' + param.data[2] + '<br/>',
										'Highest: ' + param.data[3] + '<br/>'
								].join('');
							}
						}
					},
					{
						name: 'MA5',
						type: 'line',
						data: this.calculateMA(5, chartDatas),
						smooth: true,
						lineStyle: {
							opacity: 0.5
						}
					},
					{
						name: 'MA10',
						type: 'line',
						data: this.calculateMA(10, chartDatas),
						smooth: true,
						lineStyle: {
							opacity: 0.5
						}
					},
					{
						name: 'MA20',
						type: 'line',
						data: this.calculateMA(20, chartDatas),
						smooth: true,
						lineStyle: {
							opacity: 0.5
						}
					},
					{
						name: 'MA30',
						type: 'line',
						data: this.calculateMA(30, chartDatas),
						smooth: true,
						lineStyle: {
							opacity: 0.5
						}
					},
					{
						name: 'Volume',
						type: 'bar',
						xAxisIndex: 1,
						yAxisIndex: 1,
						data: chartDatas.volumes
					}]
				};

				return option;
			},

			drawChart(chartDom, option, dispatchAction=null){
				if (this.echart != null && this.echart != "" && this.echart != undefined){
				    this.echart.dispose();
				}
				this.echart = this.$echarts.init(chartDom);

				this.echart.setOption(option);

				if(dispatchAction!=null){
					this.echart.dispatchAction(dispatchAction);
				}
			},

			handleClick(tab, event) {
				// console.log(tab)	//页面下的元素
				// console.log(event);	//事件
				if(this.activeName == "daily"){
					this.daily();
				}else if(this.activeName == "weekly"){
					this.weekly();
				}else if(this.activeName == "monthly"){
					this.monthly();
				}else if(this.activeName == "minute"){
					this.minute2();
				}else if(this.activeName == "fiveDay"){
					this.fiveDayMinute();
				}
			},

			init(){
				let zhangdie = this.quotationData[31];
				if(zhangdie > 0){
					this.$refs.zhangfu.style.color = "red";
					this.$refs.zhangdie.style.color = "red";
				}else{
					this.$refs.zhangfu.style.color = "green";
					this.$refs.zhangdie.style.color = "green";
				}

				let xianjia = this.quotationData[3];
				let zuoshou = this.quotationData[4];
				let jinkai = this.quotationData[5];
				let zuigao = this.quotationData[33];
				let zuidi = this.quotationData[34];
				if(zuoshou < xianjia){
					this.$refs.xianjia.style.color = "red";
				}else{
					this.$refs.xianjia.style.color = "green";
				}
				if(zuoshou < jinkai){
					this.$refs.jinkai.style.color = "red";
				}else{
					this.$refs.jinkai.style.color = "green";
				}
				if(zuoshou < zuigao){
					this.$refs.zuigao.style.color = "red";
				}else{
					this.$refs.zuigao.style.color = "green";
				}
				if(zuoshou < zuidi){
					this.$refs.zuidi.style.color = "red";
				}else{
					this.$refs.zuidi.style.color = "green";
				}
			},
			remind(){
				this.$message({
					message: '该功能暂不开放',
					type: 'warning'
				});
			},
			recommend(){
				this.$message({
					message: '该功能暂不开放',
					type: 'warning'
				});
			}
		},
		created() {

		},
		mounted() {
			this.stockNum = this.$store.getters.getStockNum;
			this.quotation();
			// this.pkAnalyse();
			// this.minute2();

			let _this = this;
			this.secondInterval = setInterval(function(){
				let time = new Date();
				if((time.getHours()>=9 && time.getHours()<=12) || (time.getHours()>=13 && time.getHours()<=15)){
					this.refresh = true;
				}else{
					this.refresh = false;
				};
			},1*1000);
			this.minuteInterval = setInterval(function(){
				if(this.refresh){
					_this.quotation();
				};
			},5*1000);
		},
		destroyed() {
			window.clearInterval(this.secondInterval);
			window.clearInterval(this.minuteInterval);
		},
	}
</script>

<style scoped>
	.stock {
		position: relative;
		width: 100%;
		text-align: left;
	}

	.stock .tool{
		margin-bottom: 5px;
	}

	.stock .stockSearch {
		display: table;
	}
	.stock .stockSearch .el-input {
		display: table-cell;
	}
	.stock .stockSearch .el-button {
		display: table-cell;
	}

	.stock .name {
		display: table;
		font-size: 30px;
	}
	.stock .name span {
		display: table-cell;
		width: 150px;
	}

	.stock .time {
		display: table;
	}
	.stock .time span:nth-of-type(2) {
		display: table-cell;
		padding-left: 15px;
	}

	.stock .stockDatas {
		display: table;
	}
	.stock .stockDatas div span {
		display: table-cell;
		width: 100px;
	}

	/* .stock .market {
		display: table;
	} */
	.stock .market div {
		display: table-row;
	}
	.stock .market div span {
		display: table-cell;
	}
	@media screen and (min-width: 1200px){
		.stock .stockDatas div {
			display: inline-table;
		}

		.drawChart2{
			height: 400px;
		}
		.minute{
			display: table;
		}
		.minute .drawChart1{
			display: table-cell;
			width: 83%;
			height: 400px;
		}
		.minute .market{
			display: table-cell;
			vertical-align:top;
			width: 17%;
		}
		.minute .market div{
			height: 35px;
		}
		.minute .market div span{
			width: 70px;
		}
	}
	@media screen and (max-width: 1200px){
		.stock .stockDatas div {
			display: inline-table;
		}

		.drawChart2{
			height: 400px;
		}
		.minute{
			display: table;
		}
		.minute .drawChart1{
			display: table-cell;
			width: 71%;
			height: 400px;
		}
		.minute .market{
			display: table-cell;
			vertical-align:top;
			width: 29%;
		}
		.minute .market div{
			height: 35px;
		}
		.minute .market div span{
			width: 70px;
		}
	}
	@media screen and (max-width: 767px){
		.stock .stockDatas div {
			display: table-row;
		}

		.drawChart2{
			height: 300px;
		}
		.minute{
			display: table;
			width: 100%;
		}
		.minute .drawChart1{
			display: table-row;
			width: 100%;
			height: 300px;
		}
		.minute .market{
			display: table;
			vertical-align:top;
			font-size: 12px;
			width: 100%;
		}
		.minute .market div{
			display: table-row;
			width: 100%;
			height: 16px;
		}
		.minute .market div span{
			width: 45%;
		}
	}
</style>
